header {
  padding: 10px;
}

.App-Header {
  margin: 0px 50px 0px 0px;
}

.Navigation-item {
  display: flex;
}

.Navigation-item li {
  padding: 0px 15px;
  height: 1.2em;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: lighter;
  border-right: 1px solid #000;
}

.Navigation-item li a {
  color: #3e3e3e;
}

.Navigation-item li:first-child {
  margin-right: auto;
  border-right: none;
}

.Navigation-item li:last-child {
  border-right: none;
}

.Logo {
  margin: 15px;
  font-size: 1.2em;
  text-decoration: none;
  font-weight: bolder;
  color: #3e3e3e;
}

.Logo-title {
  margin: 5px;
}

.Logo-name {
  margin: 5px;
}

.Logo a:visited {
  color: #3e3e3e;
}

.green {
  color: #52973c;
}

.Nav-description {
  margin: 10px 0;
  font-size: 1em;
  font-weight: 300;
}

.currentDirectory {
  border-bottom: 2px solid #308714;
  padding-bottom: 4px;
}

#nav-open {
  display: none;
}

@media (max-width: 767px) {
  header {
    padding: 10px;
  }

  .Logo {
    margin: 5px;
    font-size: 1.1em;
    font-weight: 500;
  }

  .Logo-title {
    margin: 5px;
  }

  .Logo-name {
    margin: 5px;
  }

  .Nav-description {
    margin: 10px 0;
    font-size: 0.9em;
    font-weight: 300;
  }

  #nav-drawer {
    position: relative;
    text-align: left;
  }

  /*チェックボックス等は非表示に*/
  .nav-unshown {
    display: none;
  }

  /*アイコンのスペース*/
  #nav-open {
    display: inline-block;
    width: 30px;
    height: 22px;
    vertical-align: middle;
    position: absolute;
    right: 10px;
    top: 3px;
  }

  /*ハンバーガーアイコンをCSSだけで表現*/
  #nav-open span,
  #nav-open span:before,
  #nav-open span:after {
    position: absolute;
    height: 3px; /*線の太さ*/
    width: 25px; /*長さ*/
    border-radius: 3px;
    background: #555;
    display: block;
    content: '';
    cursor: pointer;
  }
  #nav-open span:before {
    bottom: -8px;
  }
  #nav-open span:after {
    bottom: -16px;
  }

  /*閉じる用の薄黒カバー*/
  #nav-close {
    display: none; /*はじめは隠しておく*/
    position: fixed;
    z-index: 99;
    top: 0; /*全体に広がるように*/
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0;
    transition: 0.3s ease-in-out;
  }

  /*中身*/
  #nav-content {
    overflow: auto;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999; /*最前面に*/
    width: 90%; /*右側に隙間を作る（閉じるカバーを表示）*/
    max-width: 330px; /*最大幅（調整してください）*/
    height: 100%;
    background: #fff; /*背景色*/
    transition: 0.3s ease-in-out; /*滑らかに表示*/
    -webkit-transform: translateX(-105%);
    transform: translateX(-105%); /*左に隠しておく*/
  }

  /*チェックが入ったらもろもろ表示*/
  #nav-input:checked ~ #nav-close {
    display: block; /*カバーを表示*/
    opacity: 0.5;
  }

  #nav-input:checked ~ #nav-content {
    -webkit-transform: translateX(0%);
    transform: translateX(0%); /*中身を表示（右へスライド）*/
    box-shadow: 6px 0 25px rgba(0, 0, 0, 0.15);
  }

  .Navigation-item {
    display: flex;
    flex-direction: column;
    text-align: left;
  }

  .Navigation-item li {
    align-items: left;
    justify-content: left;
    padding: 1em;
  }

  .Navigation-item li:first-child {
    margin-right: 0px;
  }
}
