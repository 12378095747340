.Contact-Main {
  width: 50vw;
  margin: 30px auto 0;
  background: #ffffff;
}

.Contact-header-h1 {
  position: relative;
  font-weight: 300;
  margin-top: 3vw;
}

.Contact-header-h1:before {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 2px; /*線の上下位置*/
  display: inline-block;
  width: 8em; /*線の長さ*/
  height: 0.3em; /*線の太さ*/
  -moz-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%); /*位置調整*/
  background-color: #5bb754; /*線の色*/
  border-radius: 2px; /*線の丸み*/
  opacity: 0.5;
}

.form-group {
  text-align: left;
}

.form-title {
  text-align: left;
  color: #2a7b3e;
  margin-top: 20px;
}

.form-button {
  margin: 20px;
}

.after-sent {
  margin: 20vh 0;
}

@media (max-width: 767px) {
  .Contact-Main {
    width: 90vw;
    margin: 30px auto 0;
    background: #ffffff;
  }

  .Contact-header-h1 {
    position: relative;
    font-weight: 300;
    margin-top: 3vw;
  }

  .Contact-header-h1 {
    position: relative;
    width: 90vw;
    font-weight: 300;
    margin-top: 3vw;
  }

  .Contact-Main {
    margin: 0 auto;
  }
}
