.Home-Image-PC {
  width: 100vw;
}

.Home-Image-PC img {
  width: 100vw;
  object-fit: contain;
}

.Background-gray {
  background: #f5f5f5;
}

.Home-Body {
  width: 860px;
  display: flex;
  margin: 30px auto 0;
  background: #ffffff;
}

.Home-Main {
  width: 510px;
}

.Home-Side {
  width: 350px;
  background: #f5f5f5;
  margin-left: 5px;
}

.Home-Separator {
  width: 100vw;
  height: 10px;
  position: relative;
  background: #f5f5f5;
}

.Home-Main .Home-Profile {
  margin: 50px 0;
  background: #ffffff;
}

.Home-Main .Home-Profile table {
  margin: 20px 0;
  text-align: left;
  padding: 5px;
  position: relative;
}

.Home-Main .Home-Profile table th {
  padding: 5px 10px;
  font-weight: normal;
}

.Home-Main .Home-Profile table td {
  padding: 5px 10px;
}

.Home-Main .Home-Policy {
  margin: 50px 0;
  background: #ffffff;
}

.Home-Main .Home-Policy img {
  width: auto;
  max-width: 450px;
  min-width: 400px;
  margin: 30px 0;
}

.Home-Main .Home-Policy p {
  margin: 10px auto;
  padding: 10px 20px;
  text-align: left;
}

.Home-Main .Home-Policy ol {
  padding: 0.3em 0.5em;
  list-style-type: none;
  border: none;
  counter-reset: number;
}

.Home-Main .Home-Policy ol li {
  position: relative;
  line-height: 1.3;
  padding: 0.5em 0 0.5em 1.4em;
  font-size: 2em;
}

.Home-Main .Home-Policy ol li:before {
  position: absolute;
  content: counter(number);
  counter-increment: number;
  left: 0;
  width: 40px;
  height: 40px;
  border-radius: 10%;
  background: #5ee57e;
  color: white;
  font-size: 0.9em;
  text-align: center;
  vertical-align: middle;
  line-height: 40px;
}

.Home-Donation {
  margin: 50px 0;
  background: #ffffff;
}

.Home-Donation p {
  margin: 10px auto;
  padding: 20px;
  text-align: center;
}

.Home-Donation .red {
  font-size: 0.8em;
  margin: 10px auto;
  padding: 10px 20px;
  text-align: left;
}

.Home-Donation .big {
  font-size: 1.2em;
  font-weight: 500;
}

.Home-Donation ul {
  width: 60vw;
  margin: 0 auto;
  text-align: left;
  list-style-type: disc;
}

.Home-Contact {
  margin: 50px 0;
  background: #ffffff;
}

.Home-Contact p {
  margin: 30px 0 50px;
  padding: 20px;
}

.fb-contents {
  position: sticky;
  top: 10px;
}

.Footer-Contact p {
  margin: 10px auto;
  padding: 20px;
  text-align: center;
}

@media (max-width: 767px) {
  .Home-Image-Mobile {
    width: 100vw;
  }

  .Home-Image-Mobile img {
    width: 100vw;
    object-fit: contain;
  }

  .Home-Body {
    width: 100vw;
    display: unset;
  }

  .Home-Main {
    width: 100vw;
    margin: 10px auto;
  }

  .Home-Side {
    width: 100vw;
    margin: 10px auto 0;
    padding: 20px 0;
  }

  .Home-Main .Home-Profile {
    margin: 10px 0;
    padding: 20px 0;
    font-size: 0.8em;
  }

  .Home-Main .Home-Profile table {
    margin: 10px 0;
    text-align: left;
    padding: 0 5vw;
    position: relative;
  }

  .Home-Main .Home-Profile table th {
    width: 15vw;
  }

  .Home-Main .Home-Policy {
    margin: 10px 0;
    padding: 20px 0;
  }

  .Home-Main .Home-Policy img {
    width: auto;
    max-width: 70vw;
    min-width: 50vw;
    margin: 30px 0;
  }

  .Home-Main .Home-Policy p {
    width: 80vw;
    margin: 10px auto;
    text-align: left;
    font-size: 0.8em;
  }

  .Home-Main .Home-Policy .left {
    padding-left: 20px;
  }

  .Home-Main .Home-Policy ol li {
    width: 80vw;
    position: relative;
    line-height: 1.5;
    padding: 0.5em 1em 0.5em 2.3em;
    font-size: 1.2em;
    font-weight: 400;
  }

  .Home-Main .Home-Policy ol li:before {
    position: absolute;
    content: counter(number);
    counter-increment: number;
    left: 5vw;
    width: 30px;
    height: 30px;
    border-radius: 10%;
    background: #5ee57e;
    color: white;
    font-size: 0.9em;
    text-align: center;
    vertical-align: middle;
    line-height: 30px;
  }

  .Home-Donation {
    margin: 10px 0;
    padding: 20px 0;
  }

  .Home-Donation p {
    width: 80vw;
    margin: 20px auto;
    text-align: left;
  }

  .Home-Donation ul {
    width: 80vw;
    margin: 0 auto;
    text-align: left;
    list-style-type: disc;
  }

  .Home-Contact {
    margin: 10px 0;
    padding: 20px 0;
  }
}
