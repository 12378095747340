footer {
  background: #f5f5f5;
  padding-top: 5px;
}

.Information {
  margin: 25px 50px;
  padding: 0 50px;
  text-align: left;
}

.Information h2 {
  font-size: 1.6em;
  font-weight: normal;
}

.Information p {
  font-size: 0.9em;
  line-height: 1.4em;
}

.SNS {
  display: flex;
  align-items: center;
}

.SNS-button-image {
  width: 30px;
  height: 30px;
  margin-right: 5px;
}

.Copy-rights {
  height: 4em;
  background-color: #31bb53;
  color: white;
  font-weight: 100;
  letter-spacing: 0.1em;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 767px) {
  .Information {
    margin: 25px 50px;
    padding: 0;
  }

  .Copy-rights {
    font-size: 0.9em;
  }
}
