.Background-white {
  background: #ffffff;
}

.Profile-Image {
  width: 100vw;
  position: relative;
}

.Profile-Image img {
  width: auto;
  max-width: 100vw;
}

.Profile-Image h3 {
  position: absolute;
  width: 100vw;
  font-size: 4em;
  top: 60%;
  right: 20px;
  color: white;
  text-align: right;
  border: none;
}

.Profile-Main {
  width: 80vw;
  margin: 30px auto 0;
  background: #ffffff;
}

.Profile-Main .Profile-Profile {
  width: 60vw;
  margin: 50px auto;
  padding-top: 30px;
}

.Profile-Main .Profile-Profile h4 {
  text-align: left;
  width: 20vw;
  margin: 2px 0;
  padding: 2px;
  font-size: 1.6em;
  font-weight: 400;
  border-bottom: 4px solid #5ee57e;
}

.Profile-Main .Profile-Profile table {
  margin: 20px 0;
  text-align: left;
  position: relative;
}

.Profile-Main .Profile-Profile table th {
  padding: 5px 10px;
  font-weight: normal;
}

.Profile-Main .Profile-Profile table td {
  padding: 5px 10px;
}

.Profile-Main .Profile-Profile h5 {
  text-align: left;
  margin: 30px 0 5px;
  padding: 2px;
  font-size: 1.3em;
  font-weight: 400;
}

.Profile-Main .Profile-Profile img {
  width: auto;
  height: auto;
  max-height: 50vh;
  min-height: 400px;
  max-width: 50vw;
  min-width: 400px;
  margin: 5px 0 20px;
}

.Profile-Main .Profile-Profile p {
  text-align: left;
}

.Profile-Main .Profile-Profile ul {
  text-align: left;
}

.Profile-Main .Profile-Profile ul li {
  position: relative;
  padding: 10px 5px;
}

.Profile-Main .Profile-Profile ul li:before {
  position: absolute;
  content: '●';
  color: #31bb53;
  left: -15px;
  top: 0.8em;
}
/* 
.Profile-Main .Profile-Profile ul li:before {
    position: absolute;
    content: "";
    left: 23vw;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background: #5ee57e;
} */

.Home-Contact {
  margin: 50px 0;
}

.Home-Contact p {
  margin: 30px 0 50px;
}

@media (max-width: 767px) {
  .Profile-Main .Separator {
    height: 20px;
  }

  .Profile-Image h3 {
    position: absolute;
    width: 100vw;
    font-size: 1.8em;
    top: 60%;
    color: white;
    text-align: right;
    border: none;
  }

  .Profile-Main .Profile-Profile {
    position: relative;
    width: 80vw;
    margin: 20px auto 50px;
  }

  .Profile-Main .Profile-Profile table {
    font-size: 0.8em;
  }

  .Profile-Main .Profile-Profile table th {
    width: 20vw;
    padding: 5px 0;
  }

  .Profile-Main .Profile-Profile table td {
    padding: 5px 0 5px 5px;
  }

  .Profile-Main .Profile-Profile h4 {
    width: 40vw;
  }

  .Profile-Main .Profile-Profile p {
    font-size: 0.8em;
  }

  .Profile-Main .Profile-Profile img {
    width: auto;
    height: auto;
    max-height: 50vh;
    min-height: 300px;
    max-width: 100vw;
    min-width: 80vw;
    margin: 5px -10vw 0;
  }

  .Profile-Main .Profile-Profile ul li {
    padding: 5px;
    font-size: 0.8em;
  }

  .Profile-Main .Profile-Profile ul li:before {
    top: 0.4em;
  }
}
