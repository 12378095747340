.Separator {
  width: 100vw;
  height: 10px;
  position: relative;
  background: #f5f5f5;
  margin-left: -10vw;
}

.Contents-detail-button {
  width: 300px;
  height: 100px;
  margin: 40px 15px;
  padding: 0.9em 30px;
  background-color: #31bb53;
  background-size: cover;
  text-align: center;
  text-decoration: none;
  font-size: 1em;
  color: white;
}

.Contents-detail-button a {
  color: white !important;
}

.Contents-detail-button a:visited {
  color: white !important;
}

.left {
  text-align: left;
}

.red {
  color: #bc1212;
}

img {
  object-fit: contain;
}

h3 {
  text-align: center;
  width: 15vw;
  margin: 5px auto 40px;
  padding: 2px;
  font-size: 1.3em;
  border-bottom: 6px solid #5ee57e;
}

h4 {
  text-align: center;
  margin: 5px auto;
  padding: 2px;
  font-size: 1.6em;
  font-weight: 400;
  border-bottom: 12px solid #5ee57e;
}

.Footer-Contact {
  margin: 10px 0;
  padding: 20px 0;
  background: #ffffff;
}

@media (max-width: 767px) {
  h3 {
    text-align: center;
    width: 40vw;
    margin: 5px auto 20px;
    padding: 2px;
    font-size: 1.3em;
    border-bottom: 6px solid #5ee57e;
  }

  h4 {
    text-align: center;
    width: 80vw;
    margin: 5px auto;
    padding: 2px;
    font-size: 1.6em;
    font-weight: 400;
    border-bottom: 12px solid #5ee57e;
  }

  .Separator {
    margin-left: -10vw;
  }
}
