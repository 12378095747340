.Donation-header-h1 {
  position: relative;
  font-weight: 300;
  margin-top: 3vw;
}

.Donation-header-h1:before {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 2px; /*線の上下位置*/
  display: inline-block;
  width: 8em; /*線の長さ*/
  height: 0.3em; /*線の太さ*/
  -moz-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%); /*位置調整*/
  background-color: #5bb754; /*線の色*/
  border-radius: 2px; /*線の丸み*/
  opacity: 0.5;
}

.Donation-contents {
  margin: 30px auto;
  width: 80vw;
}

.Donation-contents section {
  width: 40vw;
  margin: 2vw auto;
}

.Donation-contents section h2 {
  font-size: 1.6vmax;
  font-weight: 600;
  padding-top: 30px;
}

.Donation-contents section h3 {
  font-size: 1.4vmax;
  font-weight: 500;
  margin: 30px auto 20px;
  width: 40vw;
}

.Donation-contents section p {
  font-size: 0.9em;
  line-height: 2em;
}

.Donation-alert {
  margin: 20px 0 60px;
  padding: 0;
}

.Donation-alert li {
  margin: 5px 0;
}

.Donation-contents .link {
  border-bottom: 1px solid #308714;
}

.p-left {
  text-align: left;
}

@media (max-width: 767px) {
  .Donation-header-h1 {
    position: static;
    z-index: 1;
    top: 1%;
    text-align: center;
    font-weight: 300;
    margin: 0;
  }

  .Donation-header-h1:before {
    top: 3em;
  }

  .Donation-contents section {
    width: 80vw;
  }

  .Donation-contents section h2 {
    font-size: 1.2em;
  }

  .Donation-contents section h3 {
    font-size: 1em;
    margin: 30px auto 20px;
    width: 80vw;
  }
}
