body {
  
}

.App {
  text-align: center;
  color: #3E3E3E;
  font-family: Helvetica Neue, Helvetica, Hiragino Sans, 'ヒラギノ角ゴ ProN W3', Hiragino Kaku Gothic ProN, "メイリオ", Meiryo, sans-serif;
}

ul {
  list-style-type: none;
}

/* a {
    color: #3E3E3E;
}

a:visited {
    color: #3E3E3E;
} */

.link {
    text-decoration: none;
}