.Policy-Image {
  width: 100vw;
  position: relative;
}

.Policy-Image img {
  width: auto;
  max-width: 100vw;
}

.Policy-Image h3 {
  position: absolute;
  width: 100vw;
  font-size: 4em;
  top: 60%;
  right: 20px;
  color: white;
  text-align: right;
  border: none;
}

.Policy-Main {
  width: 80vw;
  margin: 30px auto 0;
  background: #ffffff;
}

.Policy-Main .Policy-Policy {
  margin: 50px 0;
  padding-top: 30px;
}

.Policy-Main .Policy-Policy img {
  width: auto;
  max-width: 50vw;
  min-width: 400px;
  margin: 30px 0;
}

.Policy-Main .Policy-Policy .Lead {
  width: 65vw;
  margin: 10px auto;
  text-align: left;
  font-size: 1em;
  line-height: 1.5;
}

.Policy-Main .Policy-Policy .Policy-Article {
  width: 65vw;
  margin: 10px auto;
  text-align: left;
  font-size: 0.5em;
  line-height: 1.8;
}

.Policy-Main .Policy-Policy ol {
  padding: 0.3em 0.5em;
  list-style-type: none;
  border: none;
  counter-reset: number;
}

.Policy-Main .Policy-Policy ol li {
  position: relative;
  line-height: 1.3;
  padding: 0.5em 0 0.5em 1.4em;
  font-size: 2em;
}

.Policy-Main .Policy-Policy ol li:before {
  position: absolute;
  content: counter(number);
  counter-increment: number;
  left: 0;
  width: 40px;
  height: 40px;
  border-radius: 10%;
  background: #5ee57e;
  color: white;
  font-size: 0.9em;
  text-align: center;
  vertical-align: middle;
  line-height: 40px;
}

@media (max-width: 767px) {
  .Policy-Image h3 {
    position: absolute;
    width: 100vw;
    font-size: 1.8em;
    top: 60%;
    color: white;
    text-align: right;
    border: none;
  }

  .Policy-Main {
    margin: 0 auto;
  }

  .Policy-Main .Policy-Policy {
    margin: 0;
  }

  .Policy-Main .Policy-Policy .Lead {
    width: 80vw;
    font-size: 0.8em;
  }

  .Policy-Main .Policy-Policy ol li {
    position: relative;
    line-height: 1.5;
    font-size: 1.2em;
    font-weight: 400;
  }

  .Policy-Main .Policy-Policy ol li:before {
    position: absolute;
    content: counter(number);
    counter-increment: number;
    width: 30px;
    height: 30px;
    border-radius: 10%;
    background: #5ee57e;
    color: white;
    font-size: 0.9em;
    text-align: center;
    vertical-align: middle;
    line-height: 30px;
  }

  .Policy-Main .Policy-Policy img {
    width: auto;
    max-width: 80vw;
    min-width: 70vw;
    margin: 30px 0 0 -7.5vw;
  }

  .Policy-Main .Policy-Policy .Policy-Article {
    width: 70vw;
    font-size: 0.6em;
    margin: 30px 0 30px -3vw;
  }
}
